import type { WebItemLocationQuery } from './__types__/WebItemLocationQuery';

declare const window: {
	__SSR_ATL_TOKEN__: string;
} & Window;

/**
 * Replaces the atl-token placeholder {atlTokenPlaceholderWillBeReplacedInFrontend}
 * in the webItemSections items urls with the atl-token from AJS
 * @param data
 */
export function replaceAtlTokenPlaceholder(data: WebItemLocationQuery): WebItemLocationQuery {
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	const atlToken = process.env.REACT_SSR
		? window.__SSR_ATL_TOKEN__
		: (document.querySelector('meta[name="ajs-atl-token"]') as HTMLMetaElement)?.content;
	if (!atlToken || !data || !data.webItemSections) {
		return data;
	}
	return {
		...data!,
		webItemSections: data.webItemSections.map((section) => {
			if (section) {
				return {
					...section!,
					items: section?.items.map((sectionItem) => {
						if (sectionItem) {
							return {
								...sectionItem!,
								url:
									sectionItem.url?.replace(
										'{atlTokenPlaceholderWillBeReplacedInFrontend}',
										atlToken,
									) ?? sectionItem.url,
								urlWithoutContextPath:
									sectionItem.urlWithoutContextPath?.replace(
										'{atlTokenPlaceholderWillBeReplacedInFrontend}',
										atlToken,
									) ?? sectionItem.urlWithoutContextPath,
							};
						} else {
							return null;
						}
					}),
				};
			} else {
				return null;
			}
		}),
	};
}
