/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { usePageSpaceKey } from '@confluence/page-context';

import type {
	useContentOwnershipSpaceAdminsSpaceAdminsListQuery as useContentOwnershipSpaceAdminsSpaceAdminsListQuery$data,
	useContentOwnershipSpaceAdminsSpaceAdminsListQueryVariables as useContentOwnershipSpaceAdminsSpaceAdminsListQuery$variables,
	useContentOwnershipSpaceAdminsSpaceAdminsListQuery_space_Space_admins as SpaceAdminType,
} from './__types__/useContentOwnershipSpaceAdminsSpaceAdminsListQuery';

type SpaceAdminsType = SpaceAdminType | null;
export const useContentOwnershipSpaceAdmins = () => {
	const [spaceKey] = usePageSpaceKey();
	const [spaceAdminsList, setSpaceAdminsList] = useState<SpaceAdminsType[]>([]);
	const { data } = useQuery<
		useContentOwnershipSpaceAdminsSpaceAdminsListQuery$data,
		useContentOwnershipSpaceAdminsSpaceAdminsListQuery$variables
	>(
		gql`
			query useContentOwnershipSpaceAdminsSpaceAdminsListQuery($spaceKey: String!) {
				space(key: $spaceKey) {
					id
					admins(accountType: ATLASSIAN) {
						type
						... on KnownUser {
							email
						}
						... on User {
							email
						}
					}
				}
			}
		`,
		{
			variables: {
				spaceKey: spaceKey!,
			},
			skip: !spaceKey,
		},
	);

	useEffect(() => {
		const spaceAdmins = data?.space?.admins || [];
		setSpaceAdminsList(spaceAdmins);
	}, [data]);

	return spaceAdminsList;
};
