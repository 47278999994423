export const CONTENT_METADATA_JIRA_ID = 'content-metadata-jira';
export const CONTENT_METADATA_ATTACHMENTS_ID = 'content-metadata-attachments';
export const CONTENT_METADATA_PAGE_RESTRICTIONS_ID = 'content-metadata-page-restrictions';
export const DRAFT_STATUS_LOZENGE_ID = 'draft-status-lozenge';
export const SCHEDULED_STATUS_LOZENGE_ID = 'status-lozenge-scheduled';
export const EDIT_PAGE_METADATA_RESTRICTIONS_ID = 'rte-button-restrictions';
export const INVITE_TO_EDIT_ID = 'inviteToEdit';
export const ANALYTICS_ADDON_BYLINE_ID = 'com.addonengine.analytics__analytics-content-byline-item';
export const ANALYTICS_ADDON_ACTIONS_MENU_ID =
	'com.addonengine.analytics__analytics-content-menu-item';
export const ANALYTICS_MODERATE_ADDON_ACTIONS_MENU_ID =
	'com.addonengine.analytics.moderate__analytics-content-menu-item';
