import { LoadableAfterPaint } from '@confluence/loadable';

export { i18n as InspectPermissionsI18n } from './inspectPermissionsTranslations';

export const InspectPermissionsDialog = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-InspectPermissionsDialog" */ './InspectPermissionsDialog'
			)
		).InspectPermissionsDialog,
});

export const InspectPermissionsEntryPoint = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-InspectPermissionsEntryPoint" */ './InspectPermissionsEntryPoint'
			)
		).InspectPermissionsEntryPoint,
});
