import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import { Text } from '@atlaskit/primitives';
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';
import Avatar from '@atlaskit/avatar';

import { useChoreographerAssets } from '@confluence/choreographer-services';

import { i18n } from './i18n';
import {
	FlexContainer,
	OwnerSwapBackground,
	OwnerSwapIndividualContainer,
	OwnerSwapIndividualName,
	OwnerSwapIndividualTextContainer,
	OwnerSwapIndividualTitle,
	OwnerSwapArrow,
	OwnerSwapArrowContainer,
} from './ContentOwnershipStyles';
import type { Owner } from './types';
import { CONFIRM_MODAL_LEARN_MORE_LINK } from './contentOwnershipConstants';

type ChangeOwnerConfirmationModalProps = {
	onCancel: () => void;
	onConfirm: () => void;
	currentOwner: Owner | undefined;
	newOwner: Owner;
};

export const ChangeOwnerConfirmationModal = ({
	onCancel,
	onConfirm,
	currentOwner,
	newOwner,
}: ChangeOwnerConfirmationModalProps) => {
	const intl = useIntl();
	const { Modal } = useChoreographerAssets();

	return (
		<Modal
			testId="content-owner-confirm-modal"
			onClose={onCancel}
			width="small"
			messageType="transactional"
			messageId="content-ownership-confirm-modal"
		>
			<ModalHeader>
				<ModalTitle appearance="warning">{intl.formatMessage(i18n.confirmModalTitle)}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Text as="p">
					{intl.formatMessage(i18n.confirmModalBody, {
						button: (chunks: React.ReactNode[]) => (
							<Button
								appearance="link"
								spacing="none"
								target="_blank"
								href={CONFIRM_MODAL_LEARN_MORE_LINK}
							>
								{chunks}
							</Button>
						),
					})}
				</Text>
				<OwnerSwapBackground>
					<OwnerSwapIndividualContainer data-testid="content-owner-confirm-modal-current-user">
						<Avatar size="medium" src={currentOwner?.avatarUrl} name={currentOwner?.fullName} />
						<OwnerSwapIndividualTextContainer>
							<OwnerSwapIndividualTitle>
								<FormattedMessage {...i18n.confirmModalCurrentOwner} />
							</OwnerSwapIndividualTitle>
							<OwnerSwapIndividualName>{currentOwner?.fullName}</OwnerSwapIndividualName>
						</OwnerSwapIndividualTextContainer>
					</OwnerSwapIndividualContainer>
					<OwnerSwapArrowContainer>
						<OwnerSwapArrow>
							<ArrowRightIcon color="currentColor" spacing="spacious" label="Ownership Transfer" />
						</OwnerSwapArrow>
					</OwnerSwapArrowContainer>
					<OwnerSwapIndividualContainer data-testid="content-owner-confirm-modal-new-user">
						<Avatar size="medium" src={newOwner?.avatarUrl} name={newOwner?.fullName} />
						<OwnerSwapIndividualTextContainer>
							<OwnerSwapIndividualTitle>
								<FormattedMessage {...i18n.confirmModalNewOwner} />
							</OwnerSwapIndividualTitle>
							<OwnerSwapIndividualName>{newOwner?.fullName}</OwnerSwapIndividualName>
						</OwnerSwapIndividualTextContainer>
					</OwnerSwapIndividualContainer>
				</OwnerSwapBackground>
			</ModalBody>
			<ModalFooter>
				<FlexContainer>
					<Button
						testId="content-owner-modal-cancel-update-owner-button"
						appearance="subtle"
						onClick={onCancel}
					>
						{intl.formatMessage(i18n.confirmModalCancel)}
					</Button>
					<Button
						testId="content-owner-modal-confirm-update-owner-button"
						appearance="warning"
						onClick={onConfirm}
					>
						{intl.formatMessage(i18n.confirmModalConfirmChangeOwner)}
					</Button>
				</FlexContainer>
			</ModalFooter>
		</Modal>
	);
};
