/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import type {
	useContentOwnershipContributorsContentOwnershipContributorsQuery as useContentOwnershipContributorsContentOwnershipContributorsQuery$data,
	useContentOwnershipContributorsContentOwnershipContributorsQueryVariables as useContentOwnershipContributorsContentOwnershipContributorsQuery$variables,
} from './__types__/useContentOwnershipContributorsContentOwnershipContributorsQuery';
import { DEFAULT_CONTRIBUTORS_FETCH_LIMIT } from './contentOwnershipConstants';
import { parseContributors } from './content-ownership-helpers';
import type { UserType } from './types';

export const useContentOwnershipContributors = ({
	contentId,
}: {
	contentId: string;
	spaceKey: string; // TODO Remove this unused prop
}) => {
	const [arrangedContributors, setArrangedContributors] = useState<UserType[]>([]);
	const { data, error } = useQuery<
		useContentOwnershipContributorsContentOwnershipContributorsQuery$data,
		useContentOwnershipContributorsContentOwnershipContributorsQuery$variables
	>(
		gql`
			query useContentOwnershipContributorsContentOwnershipContributorsQuery(
				$contentId: ID!
				$limit: Int = 4
			) {
				content(id: $contentId) {
					nodes {
						id
						history {
							ownedBy {
								displayName
								type
								... on KnownUser {
									accountId
								}
								... on User {
									accountId
								}
								profilePicture {
									path
								}
							}
						}
						version {
							by {
								displayName
								type
								... on KnownUser {
									accountId
								}
								... on User {
									accountId
								}
								profilePicture {
									path
								}
							}
						}
					}
				}
				contentContributors(id: $contentId, limit: $limit) {
					nodes {
						displayName
						type
						... on KnownUser {
							accountId
						}
						... on User {
							accountId
						}
						profilePicture {
							path
						}
					}
				}
			}
		`,
		{
			variables: {
				contentId,
				limit: DEFAULT_CONTRIBUTORS_FETCH_LIMIT,
			},
		},
	);

	if (error) {
		if (isUnauthorizedError(error)) {
			markErrorAsHandled(error);
		} else {
			// TODO: add analytics or experience tracking for errors
		}
	}

	useEffect(() => {
		if (data) {
			const contributors = parseContributors(data);
			setArrangedContributors(contributors);
		}
	}, [data]);

	return arrangedContributors;
};
