import React, { useState } from 'react';
import { useIntl } from 'react-intl-next';
import { Subscribe } from 'unstated';

import EditorWarningIcon from '@atlaskit/icon/core/warning';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';

import { InspectPermissionsDialog } from '@confluence/inspect-permissions';
import { RestrictionsDialogStateContainer } from '@confluence/restrictions-dialog-state-container';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { SPACE_PERMISSIONS_SETTINGS } from '@confluence/named-routes';
import { SpacePermissionsTabKey } from '@confluence/space-permissions/entry-points/spacePermissionsKeys';
import { usePageSpaceKey } from '@confluence/page-context';

import { i18n } from './i18n';
import {
	FlexColumn,
	PermissionWarningContainer,
	Text,
	TextStrong,
	Wrapper,
} from './ContentOwnershipStyles';
import { PARENT_PAGE_LEARN_MORE_URL } from './contentOwnershipConstants';
import { useIsSpaceOrSiteAdmin } from './useIsSpaceOrSiteAdmin';
import { generateMailtoLink } from './content-ownership-helpers';
import { useContentOwnershipSpaceAdmins } from './useContentOwnershipSpaceAdmins';
import { useContentInfo } from './useContentInfo';
import type { SelectedUser } from './types';

type ContentOwnershipPermissionWarningProps = {
	isParentPageRestriction?: boolean;
	isSpaceRestricted?: boolean;
	isEditPageRestricted?: boolean;
	contentId: string;
	selectedUser?: SelectedUser;
	resetRestrictions: () => void;
};

const SpaceRestrictionButton = ({ intl, displayName, contentId, selectedUser }) => {
	const [spaceKey] = usePageSpaceKey();

	const isSpaceOrSiteAdmin = useIsSpaceOrSiteAdmin();

	const spacePermissionsUrl = `${SPACE_PERMISSIONS_SETTINGS.toUrl({
		spaceKey,
		tab: SpacePermissionsTabKey.Users,
	})}`;

	const SpaceAdminRestrictionButton = () => {
		return (
			<Button appearance="link" href={spacePermissionsUrl} target="_blank">
				{intl.formatMessage(i18n.manageSpaceRestrictionButtonAdmin)}
			</Button>
		);
	};

	const SpaceUserRestrictionButton = () => {
		const spaceAdmins = useContentOwnershipSpaceAdmins();
		const { contentTitle } = useContentInfo({ contentId });

		const mailtoLink = generateMailtoLink({
			intl,
			spaceAdmins,
			spacePermissionsUrl,
			spaceKey,
			contentId,
			requestedUser: selectedUser?.name,
			currentUser: displayName,
			pageTitle: contentTitle,
		});

		return (
			<Button appearance="link" href={mailtoLink}>
				{intl.formatMessage(i18n.manageSpaceRestrictionButton)}
			</Button>
		);
	};

	return isSpaceOrSiteAdmin ? <SpaceAdminRestrictionButton /> : <SpaceUserRestrictionButton />;
};

const ParentPageRestrictionButton = ({ intl, contentId, isPremium }) => {
	const [showInspectPermissions, setShowInspectPermissions] = useState<boolean>(false);
	return (
		<>
			{isPremium ? (
				<Button appearance="link" onClick={() => setShowInspectPermissions(true)}>
					{intl.formatMessage(i18n.managePremiumRestrictionButton)}
				</Button>
			) : (
				<Button appearance="link" target="_blank" href={PARENT_PAGE_LEARN_MORE_URL}>
					{intl.formatMessage(i18n.manageParentPageRestrictionButton)}
				</Button>
			)}
			{showInspectPermissions && (
				<InspectPermissionsDialog
					contentId={contentId}
					onClose={() => setShowInspectPermissions(false)}
				/>
			)}
		</>
	);
};

const RestrictionButton = ({ intl, resetRestrictions }) => {
	return (
		<Subscribe to={[RestrictionsDialogStateContainer]}>
			{(dialogState: any) => (
				<Button
					appearance="link"
					onClick={() => {
						dialogState.setIsOpen(true);
						resetRestrictions();
					}}
				>
					{intl.formatMessage(i18n.manageRestrictionButton)}
				</Button>
			)}
		</Subscribe>
	);
};

export const ContentOwnershipPermissionWarning = ({
	isParentPageRestriction = false,
	isSpaceRestricted = false,
	isEditPageRestricted = false,
	contentId = '',
	selectedUser,
	resetRestrictions,
}: ContentOwnershipPermissionWarningProps) => {
	const intl = useIntl();
	const { edition, displayName } = useSessionData();
	const isPremium = edition === ConfluenceEdition.PREMIUM;

	// Set i18n title and message based on restrictionType
	let restrictionTitle = {};
	let restrictionMessage = {};
	if (isSpaceRestricted) {
		restrictionTitle = i18n.ownerSpaceRestrictionTitle;
		restrictionMessage = i18n.ownerSpaceRestrictionMessage;
	} else if (isPremium && isParentPageRestriction) {
		restrictionTitle = i18n.ownerPremiumRestrictionTitle;
		restrictionMessage = i18n.ownerPremiumRestrictionMessage;
	} else if (isParentPageRestriction) {
		restrictionTitle = i18n.ownerParentPageRestrictionTitle;
		restrictionMessage = i18n.ownerParentPageRestrictionMessage;
	} else if (isEditPageRestricted) {
		restrictionTitle = i18n.ownerRestrictionTitle;
		restrictionMessage = i18n.ownerRestrictionMessage;
	} else {
		restrictionTitle = i18n.ownerRestrictionTitle;
		restrictionMessage = i18n.ownerRestrictionMessage;
	}

	let restrictionButton: JSX.Element | null = null;
	if (isSpaceRestricted) {
		restrictionButton = (
			<SpaceRestrictionButton
				intl={intl}
				displayName={displayName}
				selectedUser={selectedUser}
				contentId={contentId}
			/>
		);
	} else if (isParentPageRestriction) {
		// Premium link renders inspect permissions dialog
		restrictionButton = (
			<ParentPageRestrictionButton intl={intl} contentId={contentId} isPremium={isPremium} />
		);
	} else {
		// Default opens permissions dialog
		restrictionButton = <RestrictionButton intl={intl} resetRestrictions={resetRestrictions} />;
	}

	return (
		<PermissionWarningContainer data-testid="content-ownership-permissions-warning-container">
			<Wrapper>
				<EditorWarningIcon spacing="spacious" label="" color={token('color.text.warning')} />
				<FlexColumn>
					<TextStrong>{intl.formatMessage(restrictionTitle)}</TextStrong>
					<Text>{intl.formatMessage(restrictionMessage)}</Text>
					{restrictionButton}
				</FlexColumn>
			</Wrapper>
		</PermissionWarningContainer>
	);
};
