/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import type {
	useContentInfoContentOwnershipContentInfoQuery as useContentInfoContentOwnershipContentInfoQuery$data,
	useContentInfoContentOwnershipContentInfoQueryVariables as useContentInfoContentOwnershipContentInfoQuery$variables,
} from './__types__/useContentInfoContentOwnershipContentInfoQuery';

export const useContentInfo = ({ contentId }) => {
	const [contentTitle, setContentTitle] = useState<string>('');
	const [isArchived, setIsArchived] = useState<boolean>(false);
	const { data } = useQuery<
		useContentInfoContentOwnershipContentInfoQuery$data,
		useContentInfoContentOwnershipContentInfoQuery$variables
	>(
		gql`
			query useContentInfoContentOwnershipContentInfoQuery($contentId: ID!) {
				singleContent(id: $contentId) {
					id
					title
					status
				}
			}
		`,
		{
			variables: { contentId },
		},
	);

	useEffect(() => {
		const title = data?.singleContent?.title ?? '';
		const archived = data?.singleContent?.status === 'archived';
		setContentTitle(title);
		setIsArchived(archived);
	}, [data]);

	return { contentTitle, isArchived };
};
