/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { usePageSpaceKey } from '@confluence/page-context';

import type {
	useIsSpaceOrSiteAdminSpaceAdminQuery as useIsSpaceOrSiteAdminSpaceAdminQuery$data,
	useIsSpaceOrSiteAdminSpaceAdminQueryVariables as useIsSpaceOrSiteAdminSpaceAdminQuery$variables,
} from './__types__/useIsSpaceOrSiteAdminSpaceAdminQuery';
import { useSPAViewContext } from './content-ownership-helpers';

export const useIsSpaceOrSiteAdmin = () => {
	const [spaceKey] = usePageSpaceKey();
	const { isSiteAdmin } = useSPAViewContext();
	const [isSpaceAdmin, setIsSpaceAdmin] = useState<boolean>(false);
	const { data } = useQuery<
		useIsSpaceOrSiteAdminSpaceAdminQuery$data,
		useIsSpaceOrSiteAdminSpaceAdminQuery$variables
	>(
		gql`
			query useIsSpaceOrSiteAdminSpaceAdminQuery($spaceKey: String!) {
				space(key: $spaceKey) {
					id
					currentUser {
						isAdmin
					}
				}
			}
		`,
		{
			variables: {
				spaceKey: spaceKey!,
			},
			skip: !spaceKey,
		},
	);

	useEffect(() => {
		const isAdmin = data?.space?.currentUser?.isAdmin ?? false;
		setIsSpaceAdmin(isAdmin);
	}, [data]);

	return isSiteAdmin || isSpaceAdmin;
};
