import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { UserAndGroupSearchPicker } from '@confluence/user-and-group-search';
// @ts-expect-error - Module '"@confluence/user-and-group-search"' has no exported member 'UserGroupItemProps'
// This error was introduced after upgrading to TypeScript 5
import type { UserGroupItemProps } from '@confluence/user-and-group-search';

import { useContentOwnershipContributors } from './useContentOwnershipContributors';
import type { Owner } from './types';

const userPickerContainerStyles = xcss({
	marginTop: 'space.050',
	marginBottom: 'space.250',
});

/**
 * Required to generate the ref used to attach UserPicker's menu onto
 * Copied from next/packages/restrictions/src/RestrictionsDialog/RestrictionsDialogComponent.tsx
 */
const UserPickerRefContainer = ({
	children,
}: {
	children: (refSt: HTMLElement | null) => JSX.Element | null;
}) => {
	const ref = React.useRef<HTMLElement | null>(null);
	const [refSt, setRefSt] = React.useState<HTMLElement | null>(null);

	return (
		<div
			ref={(current) => {
				ref.current = current;
				setRefSt(ref.current);
			}}
		>
			{children(refSt)}
		</div>
	);
};

type PageOwnershipDialogUserPickerProps = {
	selectedUser: any;
	onSelectUser: (any) => void;
	onClearSelectedUser: () => void;
	spaceKey: string;
	contentId: string;
	currentOwner: Owner | undefined;
};

export const ContentOwnershipDialogUserPicker = ({
	selectedUser,
	onSelectUser,
	onClearSelectedUser,
	spaceKey,
	contentId,
	currentOwner,
}: PageOwnershipDialogUserPickerProps) => {
	const bootstrapOptions = useContentOwnershipContributors({
		contentId,
		spaceKey,
	});

	// filter user dropdown results to exclude the current page owner
	const userResultsFilter = (values: UserGroupItemProps[]) => {
		const currentOwnerId = currentOwner?.id;

		return currentOwnerId ? values.filter((value) => value.id !== currentOwnerId) : values;
	};

	return (
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
		<Box xcss={userPickerContainerStyles}>
			<UserPickerRefContainer>
				{(ref) => (
					<UserAndGroupSearchPicker
						fieldId="content.owner"
						value={selectedUser}
						onSelect={onSelectUser}
						onClear={onClearSelectedUser}
						width="100%"
						isMulti={false}
						smart
						isClearable
						// @ts-expect-error - Type 'HTMLElement | null' is not assignable to type 'HTMLElement | undefined'
						// This error was introduced after upgrading to TypeScript 5
						menuPortalTarget={ref}
						bootstrapOptions={bootstrapOptions}
						smartFilterOptions={userResultsFilter}
						styles={{
							menuPortal: (css) => ({
								...css,
								position: 'absolute',
								// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
								left: 'inherit', // override positional attrs
								// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
								top: 'inherit',
							}),
						}}
						noOptionsMessage={null}
					/>
				)}
			</UserPickerRefContainer>
		</Box>
	);
};
